<template>
  <section id="site" ref="siteContent">
    <PageTitle :title="$t('cms.site.theme.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedSite && selectedSite.options.isCustomizationAdminAllowed">
        <div class="col">
          <ThemeBox></ThemeBox>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useSiteAdmin } from '../../composables/useSiteAdmin';

import ThemeBox from '../panels/theme/Theme.vue';
import Header from '../panels/Header.vue';

@Component({
  components: {
    PageTitle,
    ThemeBox,
    Header
  }
})
export default class Theme extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { sites, selectedSite } = useSiteAdmin(props, context);
    return { sites, selectedSite }
  }

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('cms.title')
    }]
  }

  mounted() {
    enableIbox(this.$refs.siteContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.siteContent as HTMLElement);
  }

}
</script>