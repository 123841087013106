<template>
  
    <form role="form" @submit="onFormSubmit" name="update-css-variables">

      <div v-if="isThemeAllowed">
        <div class="hr-line-dashed"></div>
        <div class="row">
          <div class="col-lg-12">
            <h3>{{$t('cms.site.css-variables.title')}}</h3>
          </div>
        </div>

        <div class="form-group row theme-file">
          <button class="btn btn-primary ladda-button theme-download" data-style="zoom-in" type="button" @click="downloadTheme">{{$t('cms.site.info.theme.download')}}</button>
          <b-form-file
              id="themeFile"
              v-model="form.themeFile"
              :placeholder="$t('cms.site.info.theme.upload_placeholder')"
              :drop-placeholder="$t('cms.site.info.theme.upload_drop-placeholder')"
            />
          <button class="btn btn-primary ladda-button theme-upload" data-style="zoom-in" type="button" @click="uploadTheme">{{$t('cms.site.info.theme.upload')}}</button>
        </div>

        <div class="form-group row">
          <label for="updateDarkMode" class="col-lg-3 col-form-label">{{$t('cms.site.info.theme.darkMode')}}</label>
          <div class="col-lg-9">
            <p-check id="updateDarkMode" class="p-switch p-fill" color="success" v-model="form.theme.darkMode">&nbsp;</p-check>
          </div>
        </div>

        <div class="form-group row" v-for="(variable, index) in cssVariables" :key="index + '-' + variable.key">
          <label :for="'update'+variable.key" class="col-lg-3 col-form-label">{{$t('cms.site.info.theme.'+variable.key)}} ({{variable.default}})</label>
          <div class="col-lg-9">
            <input
              :id="'update'+variable.key"
              class="form-control"
              v-model="form.theme.variables[variable.key]"
              :placeholder="$t('cms.site.info.theme.'+variable.key+'_placeholder')"
              type="text"
            />
            <input
              v-model="form.theme.variables[variable.key]"
              type="color"
            />
          </div>
        </div>

        <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('cms.site.css-variables.button')}}</button>
      </div>
    </form>

</template>

<style>

  .theme-file {
    margin-left:0px;
    margin-right:0px;
  }
  
  .theme-file > button:first-child {
    margin-right: 20px;
  }
  .theme-file > button:last-child {
    margin-left: 10px;
  }
  .theme-file .custom-file {
    width:auto;
    flex-grow:1;
  }

</style>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import { useSiteAdmin } from '../../../../composables/useSiteAdmin';
import { useTheme } from '@root/src/client/composables/useTheme';

export default defineComponent({
  props: {
    
  },
  components: {
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { sites, selectedSite, selectedSiteTopLevelDomain } = useSiteAdmin(props, context);

    const themePath = computed(() => {
      return '/api/admin/cms/site/'+selectedSite.value.site._id+'/theme'
    })
    
    const { cssVariables, update, download, upload, emptyTheme, updatePath } = useTheme({
      ...props,
      path: themePath.value
    }, context);

    const form:any = reactive({
      theme : null,
      themeFile : undefined
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;
    var laddaDownloadTheme:Ladda.LaddaButton|null = null;
    var laddaUploadTheme:Ladda.LaddaButton|null = null;

    const isThemeAllowed = computed(() => {
      return selectedSite && selectedSite.value.options.isCustomizationThemeAdminAllowed;
    })

    form.theme = {
      ...emptyTheme,
      ...(selectedSite && selectedSite.value.site.theme) ? selectedSite.value.site.theme : {}
    }

    watch(
      () => selectedSite.value.site._id,
      (val:any, oldVal:any) => {
        form.theme = {
          ...emptyTheme,
          ...(selectedSite && selectedSite.value.site.theme) ? selectedSite.value.site.theme : {}
        }
        updatePath(themePath.value);
      },
      { deep: false }
    )

    onMounted(() => {
      if(isThemeAllowed.value) {
        var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-css-variables] button.update' );
        laddaSubmit = Ladda.create(submitButton!);

        var downloadThemeButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-css-variables] button.theme-download' );
        laddaDownloadTheme = Ladda.create(downloadThemeButton!);

        var uploadThemeButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-css-variables] button.theme-upload' );
        laddaUploadTheme = Ladda.create(uploadThemeButton!);
      }
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      update(form.theme).then((response:any) => {
        if(response.updated && response.site) {  
          // We emit event as user is updated
          context.emit('site-updated', response.site);
        }
        laddaSubmit!.stop();
      });
    }

    const downloadTheme = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaDownloadTheme!.start();
      download().then((response:any) => {
        laddaDownloadTheme!.stop();
      });
    }

    const uploadTheme = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaUploadTheme!.start();
      upload(form.themeFile).then((response:any) => {
        if(response.site && response.site.theme) {
          // We update the current form
          form.theme = response.site.theme;
        }
        laddaUploadTheme!.stop();
      });
    }

    return {
      cssVariables,
      isThemeAllowed,
      form,
      onFormSubmit,
      downloadTheme,
      uploadTheme
    }
  }
})
</script>